export default {
  locate_text_message: 'Locate by text message',
  Locate_lost_phone: 'Locate lost phone',
  Locate_link: 'Locate by link',
  title: 'Track My Phones',
  buy_now: 'BUY NOW',
  month: 'month',
  subscribed_title: 'Select A Plan to Start Tracking A Phone',
  subscribed_text: 'You can upgrade or cancel subscription renewal at anytime',
  free_trial_will_expire_after: 'Free trial will expire after',
  day: 'days',
  search: 'Search...',

  // 页面 --> 登录页面
  login: {
    // 对应文本的英语: '对应文本的语种'
    sign_out: 'Sign out',
    login: 'Log in',
    email: 'Email',
    password: 'Password',
    please_enter_account: 'Please enter the account',
    incorrect_account_format: 'This email address has been registered',
    incorrect_email_format: 'Please enter a correct email address',
    please_enter_password: 'Please enter the password',
    password_maximum: 'Password not exceeding 36 characters',
    remember_account_password: 'Remember account and password',
    forget_the_password: 'Forget password?',
    no_account: 'Have no account yet?',
    register_new_account: 'Sign up account',
    create_account: 'Create an account',
    register: 'Sign up',
    return_to_previous_step: 'Back',
    click: 'By signing up,',
    that_means_you_agree_to_accept: 'you agree to',
    registration_clause: 'Privacy Policy',
    and: 'and',
    conditions_privacy_statement: 'Terms, Privacy Policy',
    policy: 'Policy',
    register_give_email_hint: 'Please enter your registered email address below, and we will send a confirmation mail for you to reset password.',
    give_email: 'Send email',
    link_failure: 'This link is no longer valid. Please try resending one to reset the password.',
    reset_passwords: 'Reset password',
    please_set_new_password: 'Please set a new account password',
    new_password: 'New password',
    new_password_again: 'Try entering password again',
    two_passwords_different: 'Password not matched',
    save: 'Save',
    or_continue_with: 'or continue with',
    verified: 'Verified',
    you_have_been_a_track_my_hones_member: 'You have been a Track My Phones member',
    verification_code: 'Verification code',
    please_enter_the_verification_code: 'Please enter the verification code',
    verify_in_email: 'Verify in email'
  },
  // 页面 --> 短信定位
  notePosition: {
    send_new_message: 'Send a new message',
    locate_record: 'Location records',
    enter_locate_phone_number: 'Enter a phone number to track',
    enter_locate_phone_number_hint: 'He/she will receive an anonymous text message to track the location of this phone number.',
    enter_phone_number: 'Please enter the phone number',
    next_step: 'Next',
    next_step_hint: 'Next, edit text message!',
    footer_system_hint: 'This software is fully compatible with iOS, Android, Windows, and MacOS.',
    enter_correct_number: 'Please enter the correct phone number',

    not_subscribed: 'You have not subscribed to this product. Subscribing allows you to',
    current: 'Current',
    after_subscription: 'After Subscription',
    send_location_sms: 'Send Location SMS',
    send_sms_to_lost_phone: 'Send SMS to Lost Phone',
    generate_location_link: 'Generate Location Link',
    subscribe_now: 'Subscribe Now'
  },
  sendNote: {
    return_previous_step: 'Back',
    locating_phone: 'Phone number you are tracking',
    edit_note: 'Edit text message',
    note_template: 'Text nessage template',
    save_note: 'Send text message',
    save_note_hint: 'A website link for tracking location will be automatically attached at the end of the text message content.',
    select_note_template: 'Select a text message template',
    select_note_template_hint: 'Text message templates with the highest CTR are preset to select here after we analyzing tons of text messages and receiver behaviors.',
    confirm: 'OK',
    save_note_footer_hint1: 'Only when the receiver clicks on the attached link in the text message can you get the location.',
    save_note_footer_hint2: 'The name of the text message sender will be marked as “INFO”, Your personal information will be strictly protected.',
    save_note_footer_hint3: 'You can edit the content of the text message to cater with reciver’s interests and make the link easier to be clicked.',
    please_input: 'Please Input',
    please_select: 'Select'
  },
  sendNoteSucceed: {
    send_succeed: 'Sent!',
    note_sent_to: 'Successfully sent to',
    recipient_clicks_note_link: 'Please wait for the receiver to click the link',
    view_location: 'View location',
    create_note_lost_phone: 'Continue sending messages to lost phone',
    create_note_phone_number: 'Send text message to another phone number',
    create_new_note_lost_phone: 'Send text message to another phone number',
    create_link_location: 'Locate by link'
  },
  locationRecord: {
    located_number: 'Tracked number',
    located: 'Location',
    search_phone_number: 'Search phone number',
    search_link_name: 'Search link name',
    description_location_status: 'Tracking status explanation',
    have_been_created: 'Created',
    have_been_created_state: 'Created: ',
    have_been_created_content: 'The tracking link is created but with no clicks yet.',
    send: 'Sent!',
    send_state: 'Sent:',
    send_content: 'Text message is sent successfully, but the receiver hasn’t read it yet.',
    GPS_positioning: 'GPS positioning',
    GPS_state: 'GPS tracking status:',
    GPS_content: 'The link is opened and the location is shared - best way to get precise location.',
    IP_positioning: 'IP positioning',
    IP_state: 'IP tracking status:',
    IP_content: 'The link is opened but the location is not shared - IP address will be analyzed to provide a approximate location.',
    send_time: 'Request sending time',
    device_type: 'Device models',
    operating_system: 'Operating systems',
    operating_system_v: 'Operating system version',
    browser_name: 'Browser',
    browser_v: 'Browser version',
    lang: 'Language',
    screen_size: 'Resolution',

    no_data: 'no data',
    location: 'Location',
    coordinate: 'Coordinate',
    site: 'Address',
    patiently_for_recipient_to_click_link: 'Please wait for the link to be clicked patiently',
    all: 'All',
    wait: 'Wait',
    link: 'Location link',
    copy: 'Copy',
    subscribe_to_view_precise_location_info: 'Subscribe to view precise location info',
    check_precise_location: 'Check Precise Location'
  },
  linkPosition: {
    location_link_name: 'Name your location link',
    not_see_name_of_link: 'The receiver will not see the name of the link',
    fill_link_name: 'Enter link name',
    enter_characters: 'Please enter 5~20 digits',
    next_step_hint: 'When the link is generated, you can share it to those you want to track using social media platforms, emails, text messages, or other methods.'
  },
  linkSucceed: {
    link_succeed: 'Link generated!',
    generate_new_link: 'Generate a new link',
    location_by_SMS: 'Locate by text message',
    copy_success: 'Copy Success',
    copy_error: 'Copy Error'
  },
  user: {
    log_out: 'Log out',
    subscribe_now: 'Subscribe Now!',
    latest_news: 'Latest',
    VIP_member: 'VIP',
    account_setting: 'Account settings',
    change_password: 'Change password',
    system_language: 'System language',
    other: 'Others',
    privacy_statement: 'Privacy Policy',
    remaining_SMS_quota_month: 'Available messages of this month:',
    subscription_package: 'Subscription Plans',
    active: 'Activated',
    expire: 'Expire on',
    total_package_duration: 'Subscription duration',
    package_amount: 'Subscription pricing',
    package_automatically_renewed: 'and it will be auto-renewed on next billing date',
    subscription_created: 'Subscription activated on '
  },
  tryOut: {
    back_to: 'Back to',
    location_tracking: 'Location tracking......',
    successfully_located: 'Successfully located!',
    carrier: 'Carrier',
    country: 'Country',
    city: 'City',
    time_zone: 'Time Zone',
    latitude: 'Latitude',
    longitude: 'Longitude',
    register_hint_text: 'Register to get precise location info',
    pay_only_to_track_precise_location: 'Pay only $1 to track precise location',
    instantly_track_the_real_time_location: 'Instantly track the real-time location',
    new_users_for_month: '$1/month for new users',
    subscribed_des1: 'Use GPS for highly precise location',
    subscribed_des2: 'Locate lost phone',
    subscribed_des3: 'Track precise geolocation',
    subscribed_des4: 'Track by link',
    subscribed_des5: 'Send tracking texts',
    subscribed_des6: 'Preset high CTR text templates'
  },
  registerSucceed: {
    hint_title: 'Payment succeeded',
    account_info_title: 'Account created',
    account_info_des: 'Congratulations！You can access all product features now. We have sent your account information and order information to your email：',
    account_info_btn: 'Check location info',
    skip_after: 'Skip after',
    seconds: 'seconds'
  },
  paymentFailure: {
    payment_failed: 'Payment failed',
    account_info_title: 'Please change your payment method',
    account_info_des: 'You are about to succeed. Try again now.',
    account_info_btn: 'Back'
  }
}
