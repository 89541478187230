export default {
  locate_text_message: '短信定位',
  Locate_lost_phone: '定位丢失的手机',
  Locate_link: '通过链接定位',
  title: 'Track My Phones',
  buy_now: '立即订阅',
  month: '个月',
  subscribed_title: '请选择一个方案来跟踪方式吧',
  subscribed_text: '你可以随时升级或取消该方案',
  free_trial_will_expire_after: '试用期剩余',
  day: '天',
  search: 'Search...',

  // 页面 --> 登录页面
  login: {
    // 对应文本的英语: '对应文本的语种'
    sign_out: '退出',
    login: '登录',
    email: '邮箱',
    password: '密码',
    please_enter_account: '请输入账号',
    incorrect_account_format: '账号格式有误',
    incorrect_email_format: '邮箱格式有误',
    please_enter_password: '请输入密码',
    password_maximum: '密码不超过36位',
    remember_account_password: '记住账号密码',
    forget_the_password: '忘记密码？',
    no_account: '没有账号? ',
    register_new_account: '注册新账号',
    create_account: '创建账号',
    register: '注册',
    return_to_previous_step: '返回上一步',
    click: '点击',
    that_means_you_agree_to_accept: '即代表你同意接受',
    registration_clause: '注册条款',
    and: '和',
    conditions_privacy_statement: '条件、隐私声明',
    policy: '政策',
    register_give_email_hint: '请在下方输入你注册的邮箱，我们将向你发送一封重置密码的邮件。',
    give_email: '发送邮件',
    link_failure: '你的重置密码连接已失效，请重新申请重置密码',
    reset_passwords: '重置密码',
    please_set_new_password: '请设置新的账号密码',
    new_password: '新密码',
    new_password_again: '再次输入新密码',
    two_passwords_different: '两次输入的密码不一致',
    save: '保存',
    or_continue_with: '或使用以下账号登录'
  },
  // 页面 --> 短信定位
  notePosition: {
    send_new_message: '发送新短信',
    locate_record: '定位记录',
    enter_locate_phone_number: '输入你要定位的手机号码',
    enter_locate_phone_number_hint: '对方将收到一条匿名的短信，用于定位此号码的手机位置',
    enter_phone_number: '请输入手机号',
    next_step: '下一步',
    next_step_hint: '进入下一步，编辑短信内容吧！',
    footer_system_hint: '此软件与iOS、Android、Windows、MacOS设备完全兼容。在软件上获得的所有数据都是完全保密的！',
    enter_correct_number: '请输入正确号码',
    not_subscribed: '你尚未订阅本产品，订阅后你可获得',
    current: '当前',
    after_subscription: '订阅后',
    send_location_sms: '发送定位短信',
    send_sms_to_lost_phone: '发送短信给丢失手机',
    generate_location_link: '生成定位链接',
    subscribe_now: '立即订阅'
  },
  sendNote: {
    return_previous_step: '返回上一步',
    locating_phone: '你在定位的手机',
    edit_note: '编辑短信内容',
    note_template: '短信模板',
    save_note: '发送短信',
    save_note_hint: '系统将自动在你的短信末尾增加用于定位的网页链接',
    select_note_template: '选择一个短信模版',
    select_note_template_hint: '根据我们的短信信息研究，我们预设了一些点击频率最高的短信模板',
    confirm: '确定',
    save_note_footer_hint1: '收件人需要点击短信末尾的链接才能获取到其定位。',
    save_note_footer_hint2: '短信的发送人姓名将显示为“INFO”，您的个人信息不会暴露。',
    save_note_footer_hint3: '你可以根据对收件人的了解，编辑一条容易被点击链接的短信内容。',
    please_input: '请输入',
    please_select: '请选择'
  },
  sendNoteSucceed: {
    send_succeed: '发送成功！',
    note_sent_to: '短信已发送到',
    recipient_clicks_note_link: '请等到收件人点击短信链接',
    view_location: '查看定位',
    create_note_lost_phone: '继续发送短信给丢失手机',
    create_note_phone_number: '发送短信到另外一个手机号码',
    create_new_note_lost_phone: '发送短信到另外一个手机号码',
    create_link_location: '通过链接定位'
  },
  locationRecord: {
    located_number: '已定位的号码：',
    located: '定位',
    search_phone_number: '搜索电话号码',
    search_link_name: '搜索链接名称',
    description_location_status: '定位状态的说明',
    have_been_created: '已创建',
    have_been_created_state: '已创建状态：',
    have_been_created_content: '定位链接创建成功，尚未有人点击链接。',
    send: '已发送',
    send_state: '已发送状态：',
    send_content: '短信已成功发送，收件人未查阅短信。',
    GPS_positioning: 'GPS定位',
    GPS_state: 'GPS定位状态：',
    GPS_content: '收件人打开了短信的链接，并分享了查阅短信时的定位，准确率最高的定位方式。',
    IP_positioning: 'IP定位',
    IP_state: 'IP定位状态：',
    IP_content: '收件人打开了短信的链接，并未分享当前定位，系统通过IP地址定位了大致的位置。',
    send_time: '发送时间',
    device_type: '设备类型',
    operating_system: '操作系统',
    operating_system_v: '操作系统版本',
    browser_name: '浏览器名称',
    browser_v: '浏览器版本',
    lang: '语言',
    screen_size: '屏幕尺寸',
    no_data: '暂无数据',
    location: '位置',
    coordinate: '坐标',
    site: '地址',
    patiently_for_recipient_to_click_link: '请耐心等候收件人点击链接',
    all: '全部',
    wait: '待发送',
    link: '定位链接',
    copy: '复制',
    subscribe_to_view_precise_location_info: '订阅套餐后可查看详细地址信息',
    check_precise_location: '查看详细地址'
  },
  linkPosition: {
    location_link_name: '给你的定位链接取个名字吧',
    not_see_name_of_link: '对方不会看到链接的名字',
    fill_link_name: '填写链接名字',
    enter_characters: '请输入5-20个字符',
    next_step_hint: '生成链接后，你可以自行通过社交软件、Email、短信等多种方式把链接发给你想要定位的人。 每次有人点击你的链接，你都可以获得他们的定位和设备信息。'
  },
  linkSucceed: {
    link_succeed: '链接已生成！',
    generate_new_link: '生成新链接',
    location_by_SMS: '通过短信定位',
    copy_success: '复制成功',
    copy_error: '复制失败'
  },
  user: {
    log_out: '退出登录',
    subscribe_now: '即刻订阅',
    latest_news: '最新动态',
    VIP_member: 'VIP会员',
    account_setting: '账号设置',
    change_password: '修改密码',
    system_language: '系统语言',
    other: '其他',
    privacy_statement: '隐私声明',
    remaining_SMS_quota_month: '本月剩余短信发送数',
    subscription_package: '订阅套餐',
    active: '生效中',
    expire: '到期',
    total_package_duration: '套餐总时长',
    package_amount: '套餐金额',
    package_automatically_renewed: '订阅将在到期日自动续订。',
    subscription_created: '订阅创建于'
  },
  tryOut: {
    back_to: '返回',
    location_tracking: '正在定位设备位置…',
    successfully_located: '成功获取定位！',
    carrier: '运营商',
    country: '国家',
    city: '城市',
    time_zone: '时区',
    latitude: '经度',
    longitude: '纬度',
    register_hint_text: '注册账号获取精准定位信息',
    pay_only_to_track_precise_location: '只需支付1美元即可追踪精确位置',
    instantly_track_the_real_time_location: '即时跟踪实时位置',
    new_users_for_month: '新用户每月1美元',
    subscribed_des1: '使用GPS进行高精度定位',
    subscribed_des2: '定位丢失手机',
    subscribed_des3: '跟踪精确的地理位置',
    subscribed_des4: '链接跟踪',
    subscribed_des5: '发送跟踪短信',
    subscribed_des6: '预设高点击率文本模板'
  },
  registerSucceed: {
    hint_title: '付款成功了',
    account_info_title: '账户已创建',
    account_info_des: '恭喜你!您现在可以访问所有产品功能。我们已经将您的账户信息和订单信息发送到您的邮箱:',
    account_info_btn: '查看位置信息',
    skip_after: '稍后',
    seconds: '秒'
  }
}
