import requests from './requests'

import { LoginForm, UserInfoRes, SubscriptionRes, UserData } from './types/user'
import { CountryRes, TemplateRes, SendSmsForm, LocationForm, LocationRes, CustomTemplateRes, SendCustomSmsForm, CreateLinkForm, UnreadQuantityForm, VerifyPhoneNumberForm, PhoneData, PhoneDataRes, LinkProductsRes } from './types/sms'

// 定义后端 API 返回的数据结构
export interface ApiResponse<T> {
  code: number;
  msg: string;
  time: string;
  data: T;
}

// 账号登录
export const login = (params: LoginForm): Promise<ApiResponse<UserInfoRes>> => {
  return requests.post('/api/user/login', params)
}

// 退出登录接口
export const logout = (): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/user/logout')
}

// 账号注册
export const register = (params: LoginForm): Promise<ApiResponse<UserInfoRes>> => {
  return requests.post('/api/user/register', params)
}

// 已登录用户修改密码接口
export const resetpwd = (params: { 'newpassword': string }): Promise<ApiResponse<UserInfoRes>> => {
  return requests.post('/api/user/resetpwd', params)
}

// 查看订单
export const orderinfo = (params: { 'order_id': string }): Promise<ApiResponse<unknown>> => { // 由于这个订单接口使用于 打点, 所以返回值类型未知
  return requests.post('/api/user/orderinfo', params)
}

// 用户详情接口
export const detail = (headers = {}): Promise<ApiResponse<UserData>> => {
  return requests.get('/api/user/detail', {
    headers
  })
}

// 订阅列表
export const getSubscription = (params: { lang: string }): Promise<ApiResponse<SubscriptionRes>> => {
  return requests.get('/api/user/getSubscription', { params })
}
// 订阅列表 - 右上角购物车订阅套餐信息接口
export const getCartSubscription = (params: { lang: string }): Promise<ApiResponse<SubscriptionRes>> => {
  return requests.get('/api/user/getCartSubscription', { params })
}

// 邮箱是否已注册接口
export const emailHasRegister = (params: { 'email': string }): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/user/emailHasRegister', params)
}

// 发送重置密码邮件接口
export const sendResetPasswordEmail = (params: { 'email': string }): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/user/sendResetPasswordEmail', params)
}

// 重置密码接口
export const resetPassword = (params: { sign: string; 'new_password': string }): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/user/resetPassword', params)
}

// 短信国家信息接口
export const country = (): Promise<ApiResponse<CountryRes[]>> => {
  return requests.get('/api/sms/country')
}

// 验证手机号码是否合法
export const verify = (params: VerifyPhoneNumberForm): Promise<ApiResponse<boolean>> => {
  // return requests.post('/api/sms/verifyPhoneNumber', params)
  return requests.get('/api/sms/verify', { params })
}

// 立即订阅
export const shareItHook = (params: object): Promise<ApiResponse<boolean>> => {
  // return requests.post('/api/sms/verifyPhoneNumber', params)
  return requests.get('/api/pay/shareItHook', { params })
}

// 获取短信模板接口
export const smsTemplate = (): Promise<ApiResponse<TemplateRes[]>> => {
  return requests.get('/api/sms/template')
}

// 发送定位短信接口
export const sendSms = (params: SendSmsForm): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/sms/sendSms', params)
}

// 短信定位列表接口
export const smsLocation = (params: LocationForm): Promise<ApiResponse<LocationRes>> => {
  return requests.get('/api/track/smsLocation', { params })
}

// 获取自定义短信模板
export const customTemplate = (): Promise<ApiResponse<CustomTemplateRes[]>> => {
  return requests.get('/api/sms/customTemplate')
}

// 获取自定义短信定位列表接口
export const customSmsLocation = (params: LocationForm): Promise<ApiResponse<LocationRes>> => {
  return requests.get('/api/track/customSmsLocation', { params })
}

// 发送自定义定位短信接口
export const sendCustomSms = (params: SendCustomSmsForm): Promise<ApiResponse<boolean>> => {
  return requests.post('/api/sms/sendCustomSms', params)
}

// 生成链接接口
export const createLink = (params: CreateLinkForm): Promise<ApiResponse<string>> => {
  return requests.post('/api/track/createLink', params)
}

// 获取自定义短信定位列表接口
export const urlLocation = (params: LocationForm): Promise<ApiResponse<LocationRes>> => {
  return requests.get('/api/track/urlLocation', { params })
}

// 未读条数接口
export const unreadQuantity = (params: UnreadQuantityForm): Promise<ApiResponse<number>> => {
  return requests.get('/api/track/unreadQuantity', { params })
}

// 获取手机号码信息
export const getPhoneData = (params: PhoneData, token: string): Promise<ApiResponse<PhoneDataRes>> => {
  return requests.get('/api/th_blog/phone_data', {
    params,
    headers: {
      token
    }
  }
  )
}

// 获取1元购买链接
export const getLinkProducts = (): Promise<ApiResponse<LinkProductsRes>> => {
  return requests.get('/api/th_pay/linkProducts')
}
