export function checkEmail (value: string): boolean {
  const reg =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}
export function validatePhoneNumber (phoneNumber: string): boolean {
  // 定义手机号的正则表达式
  const phoneRegex = /^1[3456789]\d{9}$/
  // 使用正则表达式进行匹配
  return phoneRegex.test(phoneNumber)
}

export function formatTime (timestamp: string | number): string {
  const date = new Date(timestamp) // 创建一个日期对象

  const year = date.getFullYear() // 获取年份
  const month = `0${date.getMonth() + 1}`.slice(-2) // 获取月份
  const day = `0${date.getDate()}`.slice(-2) // 获取日期
  const hours = `0${date.getHours()}`.slice(-2) // 获取小时
  const minutes = `0${date.getMinutes()}`.slice(-2) // 获取分钟
  const seconds = `0${date.getSeconds()}`.slice(-2) // 获取秒钟

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function objectToQueryString (obj: Record<string, string>): string {
  const keyValuePairs = []
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (value !== null && value !== undefined) {
        keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`)
      }
    }
  }
  return keyValuePairs.join('&')
}

export function encodeLastThird (str: string): string {
  if (!str || str.length === 0) return ''
  // 获取字符串长度
  const len = str.length
  // 计算要转码的部分的长度
  const thirdLength = Math.ceil(len / 3)
  // 提取字符串的前面部分
  const firstPart = str.substring(0, len - thirdLength)
  // 提取字符串的后面部分并进行转码
  const encodedPart = str.substring(len - thirdLength).replace(/./g, '*')
  // 返回组合后的结果
  return firstPart + encodedPart
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(fn: T, delay: number): (...args: Parameters<T>) => void {
  let t: ReturnType<typeof setTimeout> | null = null

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    if (t !== null) {
      clearTimeout(t) // 使用 clearTimeout
    }
    t = setTimeout(() => {
      fn.apply(this, args) // 使用传入的上下文 this
    }, delay)
  }
}

// 判断屏幕大小
export enum ScreenSize {
  EXTRA_LARGE = 980,
  LARGE = 880,
  MEDIUM = 780,
  SMALL = 580,
  EXTRA_SMALL = 375
}
export function getScreenSize (): ScreenSize {
  const width = window.innerWidth

  if (width >= ScreenSize.EXTRA_LARGE) {
    return ScreenSize.EXTRA_LARGE
  } else if (width >= ScreenSize.LARGE) {
    return ScreenSize.LARGE
  } else if (width >= ScreenSize.MEDIUM) {
    return ScreenSize.MEDIUM
  } else if (width >= ScreenSize.SMALL) {
    return ScreenSize.SMALL
  } else if (width >= ScreenSize.EXTRA_SMALL) {
    return ScreenSize.EXTRA_SMALL
  }

  return 980
}
export function isScreenSize (size: ScreenSize): boolean {
  return window.innerWidth >= size
}

// 获取坐标
// navigator.geolocation.getCurrentPosition(position => {
//   const latitude = position.coords.latitude
//   const longitude = position.coords.longitude
//   console.log(`获取坐标: Latitude: ${latitude}, Longitude: ${longitude}`)
// })

// // 获取设备类型
// const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'
// console.log(`获取设备类型 Device Type: ${deviceType}`)

// // 获取操作系统
// const operatingSystem = navigator.platform
// console.log(`获取操作系统 Operating System: ${operatingSystem}`)

// // 获取浏览器信息
// const browserName = navigator.appName
// const browserVersion = navigator.appVersion
// console.log(`获取浏览器信息 Browser Name: ${browserName}, Browser Version: ${browserVersion}`)

// // 获取语言
// const userLanguage = navigator.language
// console.log(`获取语言 User Language: ${userLanguage}`)

// // 获取屏幕尺寸
// const screenWidth = window.screen.width
// const screenHeight = window.screen.height
// console.log(`获取屏幕尺寸 Screen Size: ${screenWidth} x ${screenHeight}`)
