/*
 * @Author: tings
 * @Date: 2023-12-04 14:41:29
 * @LastEditors: [you name]
 * @LastEditTime: 2024-09-10 10:49:33
 * @Description:
 */
import { defineStore } from 'pinia'
import { Names } from './store_name'

import { CountryRes } from '@/api/types/sms'
import { SubscriptionRes, UserData } from '@/api/types/user'
// detail
import { country, getSubscription, getCartSubscription } from '@/api/apis'
import { LoginRuleForm } from '@/views/login/loginType'

export interface Lang {
  label: string,
  value: string
}
// 定义 state 的类型
interface SmsLocationState {
  smsPhoneNumber: string; // 发送短信的手机号
  countryList: CountryRes[]; // 国家列表
  country: CountryRes | null; // 选中的国家
  unreadQuantity: number; // 定位的未读信息
  langList: Lang[];
  lang: Lang;
  subscription: SubscriptionRes;
  cartSubscription: SubscriptionRes;
  notSubscribedDialog: boolean;
  cartSubscribedDialog: boolean;
  noCountDialog: boolean;
  isUser: boolean;
  userDetail: UserData;
  screenSize: number;
  loginRuleForm: LoginRuleForm;
}

export const useSmsLocation = defineStore(
  Names.SMS_LOCATION,
  {
    state: (): SmsLocationState => {
      const langList: Lang[] = [
        { label: '简体中文', value: 'zh-cn' },
        { label: 'English', value: 'en' }
      ]
      const localLang = JSON.parse(localStorage.getItem('lang') as string)
      let lang = localLang
      if (!localLang) {
        localStorage.setItem('lang', JSON.stringify(langList[1]))
        lang = langList[1]
      }

      const notSubscribedDialog = false
      const cartSubscribedDialog = false
      const noCountDialog = false
      const isUser = JSON.parse(localStorage.getItem('isUser') || 'false')

      const screenSize = 980

      return {
        smsPhoneNumber: '',
        country: null,
        countryList: [],
        unreadQuantity: 0,
        langList,
        lang,
        subscription: {
          card: [],
          description: '',
          title: ''
        },
        cartSubscription: {
          card: [],
          description: '',
          title: ''
        },
        notSubscribedDialog,
        cartSubscribedDialog,
        noCountDialog,
        isUser,
        userDetail: {} as UserData,
        screenSize,
        loginRuleForm: {
          email: '',
          password: ''
        }
      }
    },
    getters: {
    },
    actions: {
      async getCountryList () {
        try {
          const res = await country()
          if (res.code !== 200) return
          this.countryList = res.data
          if (!this.country) {
            this.country = res.data[0]
          }
        } catch (error) {
          console.error('Failed to fetch country list', error)
        }
      },
      langChange (lang: Lang) {
        this.lang = lang
        localStorage.setItem('lang', JSON.stringify(lang))
        setTimeout(() => {
          window.location.reload()
        }, 17)
      },
      notSubscribedDialogChange (value = true) {
        this.notSubscribedDialog = value
        // console.log('notSubscribedDialogChange', this.notSubscribedDialog)
      },
      cartSubscribedDialogChange (value = true) {
        this.cartSubscribedDialog = value
        // console.log('notSubscribedDialogChange', this.notSubscribedDialog)
      },
      closeNoCountDialogChange (value = true) {
        this.noCountDialog = value
        // console.log('notSubscribedDialogChange', this.notSubscribedDialog)
      },
      isUserChange (value = true) {
        this.isUser = value
        localStorage.setItem('isUser', JSON.stringify(value))
      },
      userDetailChange (value: UserData) {
        this.userDetail = value
      },
      async getSubscriptionList () {
        try {
          // userDetailRes
          const [subscriptionRes] = await Promise.all([
            // detail(),
            getSubscription({ lang: this.lang.value })
          ])
          if (subscriptionRes.code !== 200) return

          const subscriptionData = subscriptionRes.data.card.map(subscription => ({
            ...subscription,
            description_json: JSON.parse(subscription.description_json)
          }))

          this.subscription = {
            ...subscriptionRes.data,
            card: subscriptionData
          }
        } catch (error) {
          console.error('Failed to fetch country list', error)
        }
      },

      async getCartSubscriptionList () {
        try {
          // userDetailRes
          const [subscriptionRes] = await Promise.all([
            getCartSubscription({ lang: this.lang.value })
          ])
          if (subscriptionRes.code !== 200) return

          const subscriptionData = subscriptionRes.data.card.map(subscription => ({
            ...subscription,
            description_json: JSON.parse(subscription.description_json)
          }))

          this.cartSubscription = {
            ...subscriptionRes.data,
            card: subscriptionData
          }
        } catch (error) {
          console.error('Failed to fetch country list', error)
        }
      },
      changeScreenSize (value: number) {
        this.screenSize = value
      },
      setCountry (value: CountryRes) {
        this.country = value
      },
      setLoginRuleForm (value: LoginRuleForm) {
        this.loginRuleForm = value
      }
    }
  }
)
